<template>
  <div>
    <!-- 基础信息 -->
    <el-row style="margin-bottom: 20px">
      <div>基础信息</div>
      <el-form ref="basisData" :model="basisData" label-width="140px">
        <el-form-item style="margin-bottom: 0px" label="教学计划名称：">
          {{ basisData.crsExpPlanName }}
        </el-form-item>
        <!-- <el-form-item style="margin-bottom: 0px" label="所属学校：">
          {{ basisData.sysOrgSchoolGradeName }}
        </el-form-item> -->
        <el-form-item style="margin-bottom: 0px" label="所属学段：">
          {{ sysSemesterTermDicFilters(basisData.schoolSectionDicId) }}
        </el-form-item>
        <el-form-item style="margin-bottom: 0px" label="所属年级：">
          {{
            sysSemesterTermGradeDicListFilters(basisData.sysOrgSchoolGradeId)
          }}
        </el-form-item>
        <el-form-item style="margin-bottom: 0px" label="所属学年：">
          {{ basisData.sysSchoolTerm?.sysSchoolTermYear }}
        </el-form-item>
        <el-form-item style="margin-bottom: 0px" label="所属学期：">
          {{ basisData.sysSchoolTerm?.sysSchoolTermName }}
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 任课教师名单 -->
    <el-row>
      <div style="height: 40px; line-height: 40px">
        任课教师名单
        <el-button @click="addTeacherBtn" style="float: right" type="text"
          >+添加任教老师</el-button
        >
      </div>
      <el-table
        border
        v-loading="loading"
        ref="tableData"
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
        height="450px"
      >
        <el-table-column
          label="序号"
          type="index"
          width="50px"
        ></el-table-column>
        <el-table-column prop="sysSubjectDicId" label="科目" width="160px">
          <template slot-scope="scope">
            {{ subjectTypeFilters(scope.row.sysSubjectDicId) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="teacherName"
          label="教师名称"
          width="160px"
        ></el-table-column>
        <el-table-column
          prop="crsOpenPlanSchoolClassArrDTOList"
          label="任教班级"
        >
          <template slot-scope="scope">
            <span
              v-for="item in scope.row.crsOpenPlanSchoolClassArrDTOList"
              :key="item.sysOrgSchoolClassId"
              >{{ item.sysOrgSchoolClassName }}、</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip width="200">
          <template slot-scope="scope">
            <el-button size="mini" @click="addClassBtn(scope.row)" type="text"
              >添加班级</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              @click="deleteBtn(scope.$index)"
              style="color: red"
              type="text"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 添加任课教师弹框 添加班级弹框 -->
    <el-dialog
      width="700px"
      :title="dialogVisibleTit"
      :visible.sync="dialogVisible"
    >
      <!-- 选中框 -->
      <div style="font-size: 18px">
        已选择
        <span
          @click="deleteAllBtn"
          style="
            color: red;
            float: right;
            font-size: 15px;
            cursor: pointer;
            display: block;
          "
          >清空所有</span
        >
      </div>
      <div class="dialogVisibleChooseTop">
        <template v-if="dialogVisibleType === 'teacher'">
          <span
            v-for="(item, i) in chooseBoxList"
            class="chooseLi"
            :key="item.teacherId"
            >{{ item.teacherName
            }}<i @click="deleteIBtn(i)" class="el-icon-close"></i
          ></span>
        </template>
        <template v-else>
          <span
            v-for="(item, i) in chooseBoxList"
            class="chooseLi"
            :key="item.sysOrgSchoolClassId"
            >{{ item.sysOrgSchoolClassName
            }}<i @click="deleteIBtn(i)" class="el-icon-close"></i
          ></span>
        </template>
      </div>
      <div style="font-size: 18px">
        {{ dialogVisibleType === 'class' ? '班级列表' : '教师名单' }}
      </div>
      <!-- 条件框 -->
      <el-row>
        <el-form :inline="true" ref="dialogCondition" :model="dialogCondition">
          <template v-if="dialogVisibleType === 'teacher'">
            <el-form-item style="margin-bottom: 10px">
              <el-select
                v-model="dialogCondition.sysSubjectDicId"
                @change="changeConditionForm"
                size="mini"
                placeholder="选择科目"
              >
                <el-option label="全部" value></el-option>
                <el-option
                  v-for="item in $store.state.public_data.sysSubjectDicList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-bottom: 10px">
              <el-input
                size="mini"
                v-model="dialogCondition.teacherName"
                @change="changeConditionForm"
                placeholder="教师名称"
              ></el-input>
            </el-form-item>
          </template>
          <template v-if="dialogVisibleType === 'class'">
            <el-form-item style="margin-bottom: 10px">
              <el-input
                size="mini"
                v-model="dialogCondition.sysOrgSchoolClassName"
                @change="changeConditionForm"
                placeholder="班级名称"
              ></el-input>
            </el-form-item>
          </template>
          <el-form-item style="margin-bottom: 10px">
            <el-button size="mini" type="primary" @click="dialogInquireBtn"
              >查询</el-button
            >
            <el-button size="mini" @click="dialogResetBtn">重置</el-button>
          </el-form-item>
          <el-form-item style="margin-bottom: 10px; float: right">
            <el-button size="mini" type="primary" @click="addDataBtn"
              >批量添加</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格 -->
      <el-row v-if="dialogVisibleType === 'teacher'">
        <el-table
          border
          ref="dialogTableData"
          :data="dialogTableData"
          tooltip-effect="dark"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          style="width: 100%"
          height="340px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            :selectable="selectableRust"
            width="55"
          >
          </el-table-column>
          <el-table-column prop="sysSubjectDicId" label="科目">
            <template slot-scope="scope">
              {{ subjectTypeFilters(scope.row.sysSubjectDicId) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="teacherName"
            label="教师名称"
          ></el-table-column>
          <el-table-column label="操作" show-overflow-tooltip width="70">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                :disabled="!selectableRust(scope.row)"
                @click="addChooseData(scope.row)"
                >添加</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row v-else-if="dialogVisibleType === 'class'">
        <el-table
          border
          ref="dialogTableData"
          :data="dialogTableData"
          tooltip-effect="dark"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          style="width: 100%"
          height="340px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            :selectable="selectableRust"
            width="55"
          ></el-table-column>
          <el-table-column prop="sysSchoolClassGradeId" label="年级">
            <template slot-scope="scope">
              {{
                sysSemesterTermGradeDicListFilters(
                  scope.row.sysSchoolClassGradeId
                )
              }}
            </template>
          </el-table-column>
          <el-table-column prop="num" label="班级编号"></el-table-column>
          <el-table-column
            prop="sysOrgSchoolClassName"
            label="班级名称"
          ></el-table-column>
          <el-table-column
            prop="studentCount"
            label="班级人数"
          ></el-table-column>
          <el-table-column label="操作" show-overflow-tooltip width="70">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                :disabled="!selectableRust(scope.row)" 
                @click="addChooseData(scope.row)"
                >添加</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.totalSize"
        ></el-pagination>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" @click="dialogSaveBtn" type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCoursePlanLi,
  getTeacher,
  getSchoolClass,
  getCoursePlanTeacher,
  updateCoursePlanTeacher,
} from '@/api/courseStart/scheduleLessonsManage.js'
import { debounce } from '@/utils/util.js'
export default {
  name: 'teacherArrangement',
  data() {
    return {
      crsOpenPlanId: '',
      loading: false,
      basisData: {},
      tableData: [],
      // 弹框
      dialogVisibleType: 'teacher',
      dialogVisible: false,
      dialogVisibleTit: '',
      chooseBoxList: [],
      dialogCondition: {},
      dialogConditionFrom: {
        subjectList: [],
      },
      dialogTableData: [],
      selectionList: [],
      // 班级选中教师ID
      classTeacherId: '',
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
    }
  },
  created() {
    this.crsOpenPlanId = Number(this.$route.query.crsOpenPlanId)
  },
  mounted() {
    this.dialogConditionFrom.subjectList =
      this.$store.state.public_data.sysSubjectDicList
    this.getCoursePlanLiFunc()
    this.getCoursePlanTeacherFunc()
  },
  methods: {
    //查询开课计划
    getCoursePlanLiFunc() {
      getCoursePlanLi(this.crsOpenPlanId)
        .then((res) => {
          if (res.success) {
            this.basisData = res.data
          }
        })
        .catch((err) => {})
    },
    // 查询开课计划教师安排
    getCoursePlanTeacherFunc() {
      this.loading = true
      getCoursePlanTeacher(this.crsOpenPlanId)
        .then((res) => {
          this.loading = false
          this.dialogVisible = false
          if (res.success) {
            this.tableData = res.data
          }
        })
        .catch((err) => {})
    },
    // 添加任课教师弹框btn
    async addTeacherBtn() {
      this.page.pageIndex = 1
      this.selectionList = []
      this.dialogVisibleType = 'teacher'
      this.dialogVisibleTit = '添加任课老师'
      this.dialogCondition = {
        sysSubjectDicId: '',
        teacherCode: '',
        teacherName: '',
        sysOrgSchoolClassName: '',
      }
      this.chooseBoxList = [...this.tableData]
      await this.getTeacherFunc({})
      this.dialogVisible = true
    },
    // 添加班级弹框btn
    async addClassBtn(row, i) {
      this.page.pageIndex = 1
      this.selectionList = []
      this.dialogVisibleType = 'class'
      this.dialogVisibleTit = '添加任教班级'
      this.dialogCondition = {
        sysSubjectDicId: '',
        teacherCode: '',
        teacherName: '',
        sysOrgSchoolClassName: '',
      }
      this.classTeacherId = row.teacherId
      this.chooseBoxList = [...row.crsOpenPlanSchoolClassArrDTOList]
      this.dialogVisible = true
      await this.getSchoolClassFunc({})
    },
    // 禁用勾选
    selectableRust(row, index) {
      if (this.dialogVisibleType === 'class') {
        return !this.chooseBoxList.some(
          (e) => e.sysOrgSchoolClassId === row.sysOrgSchoolClassId
        )
      } else {
        return !this.chooseBoxList.some((e) => e.teacherId === row.teacherId)
      }
    },
    // selectableClass(row, index) {
    //   console.log('row', row)
    //   return !this.chooseBoxList.some(
    //     (e) => e.sysOrgSchoolClassId === row.sysOrgSchoolClassId
    //   )
    // },
    // 选中勾选
    handleSelectionChange(val) {
      this.selectionList = val
      console.log('selectionList', this.selectionList)
    },
    // 单个删除
    deleteIBtn(i) {
      // this.selectionList.splice(i, 1)
      this.chooseBoxList.splice(i, 1)
    },
    // 清空所有
    deleteAllBtn() {
      // this.selectionList = []
      this.chooseBoxList = []
    },
    // 教师\班级单个选中
    addChooseData(row) {
      // this.selectionList.push(row)
      this.chooseBoxList.push(row)
    },
    // 保存弹框
    dialogSaveBtn() {
      // 教师
      if (this.dialogVisibleType === 'teacher') {
        // this.tableData = [...this.tableData,this.selectionList]
        // this.selectionList
        let data = this.chooseBoxList.map((e) => {
          let crsOpenPlanSchoolClassArrDTOList =
            e.crsOpenPlanSchoolClassArrDTOList || []
          return {
            sysSubjectDicId: e.sysSubjectDicId,
            teacherId: e.teacherId,
            teacherName: e.teacherName,
            crsOpenPlanSchoolClassArrDTOList,
          }
        })
        this.tableData = this.tableData.concat(data)
        this.tableData = this.tableData.filter(
          (v, i, a) =>
            a.map((mapObj) => mapObj['teacherId']).indexOf(v['teacherId']) === i
        )
      } else {
        // 班级
        this.tableData.forEach((e) => {
          if (this.classTeacherId === e.teacherId) {
            // this.selectionList
            e.crsOpenPlanSchoolClassArrDTOList = this.chooseBoxList.map(
              (ele) => ({
                sysOrgSchoolClassName: ele.sysOrgSchoolClassName,
                sysOrgSchoolClassId: ele.sysOrgSchoolClassId,
              })
            )
            e.crsOpenPlanSchoolClassArrDTOList =
              e.crsOpenPlanSchoolClassArrDTOList.filter(
                (v, i, a) =>
                  a
                    .map((mapObj) => mapObj['sysOrgSchoolClassId'])
                    .indexOf(v['sysOrgSchoolClassId']) === i
              )
          }
        })
      }
      this.dialogVisible = false
      // this.updateCoursePlanTeacherFunc()
    },
    // 移除btn
    deleteBtn: debounce(function (i) {
      this.tableData.splice(i, 1)
      //
      // this.loading = true
      // this.updateCoursePlanTeacherFunc()
    }),
    // 添加或更新开课计划教师安排
    updateCoursePlanTeacherFunc() {
      let params = this.tableData.map((e) => {
        let sysOrgSchoolClassIdList = e.crsOpenPlanSchoolClassArrDTOList.map(
          (ele) => ele.sysOrgSchoolClassId
        )
        return {
          sysOrgSchoolClassIdList: [...sysOrgSchoolClassIdList],
          sysSubjectDicId: e.sysSubjectDicId,
          teacherId: e.teacherId,
        }
      })
      updateCoursePlanTeacher(this.crsOpenPlanId, params)
        .then((res) => {
          if (res.success) {
            this.getCoursePlanTeacherFunc()
            this.$emit('saveBtnJump')
          } else {
            this.loading = false
            this.dialogVisible = false
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {})
    },
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.changeConditionForm()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.changeConditionForm()
    },
    changeConditionForm() {
      let conditionObj = JSON.parse(JSON.stringify(this.dialogCondition))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      if (this.dialogVisibleType === 'teacher') {
        this.getTeacherFunc(conditionObj)
      } else {
        this.getSchoolClassFunc(conditionObj)
      }
    },
    // 查询
    dialogInquireBtn() {
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 重置
    dialogResetBtn() {
      this.page.pageIndex = 1
      // 条件修改
      for (let key in this.dialogCondition) {
        this.dialogCondition[key] = ''
      }
      this.changeConditionForm()
    },
    // 批量添加
    addDataBtn() {
      this.chooseBoxList = [...this.chooseBoxList, ...this.selectionList]
      this.selectionList = null
      // 去重
      if (this.dialogVisibleType === 'teacher') {
        this.chooseBoxList = this.chooseBoxList.filter(
          (v, i, a) =>
            a.map((mapObj) => mapObj['teacherId']).indexOf(v['teacherId']) === i
        )
      }else{
        this.chooseBoxList = this.chooseBoxList.filter(
          (v, i, a) =>
            a.map((mapObj) => mapObj['sysOrgSchoolClassId']).indexOf(v['sysOrgSchoolClassId']) === i
        )
      }
    },
    async getTeacherFunc(data) {
      await getTeacher(
        this.page.pageIndex,
        this.page.pageSize,
        this.crsOpenPlanId,
        data
      )
        .then((res) => {
          if (res.success) {
            this.dialogTableData = res.data
            this.page.totalSize = res.total
          }
        })
        .catch((err) => {})
    },
    async getSchoolClassFunc(data) {
      await getSchoolClass(
        this.page.pageIndex,
        this.page.pageSize,
        this.crsOpenPlanId,
        data
      )
        .then((res) => {
          if (res.success) {
            this.dialogTableData = res.data
            this.page.totalSize = res.total
          }
        })
        .catch((err) => {})
    },
    saveBtn() {
      this.updateCoursePlanTeacherFunc()
    },
    sysSemesterTermDicFilters(key) {
      return this.$store.state.public_data.sysSemesterTermDicList.find(
        (e) => e.value === key
      )?.label
    },
    subjectTypeFilters(key) {
      return this.$store.state.public_data.sysSubjectDicList.find(
        (e) => e.value === key
      )?.label
    },
    sysSemesterTermGradeDicListFilters(key) {
      const item =
        this.$store.state.public_data.sysSemesterTermGradeDicList.find((e) =>
          e.children.some((x) => x.value === key)
        )
      return item ? item.children.find((e) => e.value === key)?.label : null
    },
  },
  watch: {},
  filters: {
    dayFilters(val) {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format('YYYY-MM-DD') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
// 选中框
.dialogVisibleChooseTop {
  width: 100%;
  height: 160px;
  border: 1px solid #ccc;
  margin: 10px 0;
  border-radius: 4px;
  box-sizing: border-box;
  // padding: 10px 10px 0;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  .chooseLi {
    width: auto;
    border: 1px solid #ccc;
    padding-left: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin: 5px;
    i {
      padding: 0 10px;
      border-left: 1px solid #ccc;
      height: 100%;
      width: 35px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }
}
</style>
